import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SideNavItems, SideNavLink } from 'carbon-components-react/lib/components/UIShell';
import ReactDOM from 'react-dom';
import { StyledSideNav } from './styles';
import Particles from 'react-particles-js';
const items = [
  { name: 'Me', path: '/' },
  { name: 'Projects', path: '/projects' },
  { name: 'Work', path: '/work' },
  { name: 'Eduction', path: '/education' },
];

const Sidebar = () => {
  const location = useLocation();

  return (
    <StyledSideNav isFixedNav expanded isChildOfHeader={false} aria-label="Side navigation">
    
      <SideNavItems>
        {items.map(i => (
          <SideNavLink
            isActive={
              location.pathname === '/' && i.path === '/' ? true : location.pathname === i.path
            }
            element={Link}
            to={i.path}
            key={i.name}
          >
            {i.name}
          </SideNavLink>
        ))}
        <Particles params={{
                fps_limit: 24,
                particles: {

                  number: {
                      value: 5
                    },
                  line_linked: {
                    shadow: {
                      enable: true,
                      distance: 30,
                      color: "#3CA9D1",
                      blur: 5
                    }
                  }
                }
                ,
      polygon: {
          enable: true,
          scale: 0.5,
          type: "inline",
          move: {
              radius: 10
          },
          url: "images/small-deer.2a0425af.svg",
          inline: {
              arrangement: "equidistant"
          },
          draw: {
              enable: true,
              stroke: {
                  color: "rgba(255, 255, 255, .2)"
              }
          }
      },
              }}
              style={{
                width: '100%',
                height: '100%'
              }}/>
      </SideNavItems>

    </StyledSideNav>
  );
};

export default Sidebar;
