import styled from 'styled-components'

export const HeaderContainer = styled.div`
  @media (max-width: 640px) {
    display: ${({ isHome }) => (!isHome ? 'none' : 'block')};
  }
`

export const Header = styled.div`
  display: flex;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`

export const Image = styled.img`
  width: 200px;
  margin-right: 1rem;
  border-radius: 2px;
text-align: center;
font-family: 'Noto Sans SC', sans-serif;
border-radius: 50%;
float: center;
border: solid 0.1em
    transparent;
    animation-name: img;
    animation-duration: 4s;
    animation-delay: 1s;
    -webkit-animation-name: img;
    -webkit-animation-duration: 4s;
    -webkit-animation-delay: 1s;
    -moz-animation-name: img;
    -moz-animation-duration: 4s;
    -moz-animation-delay: 1s;
`
